.device-stats {
    height: 48px;
    margin-right: 8px;
    display: inline-block;
}

.value-subscript {
    position: absolute;
    width: 28px;
    top: 25px;
    text-align: center;
    font-size: small;
    font-weight: bold;
}

@keyframes show-action {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes hide-action {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.show {
    animation-name: show-action;
    animation-duration: 0s;
}

.hide {
    opacity: 0;
    animation-name: hide-action;
    animation-duration: 0.5s;
}