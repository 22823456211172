.badge-overlay {
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 0.7;
    z-index: 2;
}

.device-list-item .item-content {
    display: none;
}

img.device-image {
    width: 80px;
    height: 80px;
}
